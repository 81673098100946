import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { setLoading } from "../../../store/actions/actions";
import { toast } from "react-toastify";

const Sort = ({ data, onFilter }) => {
  const [filterKey, setFilterKey] = useState("");
  const [availableKeys, setAvailableKeys] = useState([]);
  const dispatch = useDispatch();
  const handleLoading = (value) => {
    dispatch(setLoading(value));
  };

  // The keys to look for in the object
  const keysToFilter = [
    "isPriority",
    "updatedOn",
    "updatedAt",
    "isActive",
    "isDisplayed",
    "isVerified",
  ];

  useEffect(() => {
    if (Array.isArray(data) && data.length > 0) {
      // Search through all keys in the data to find matches
      const findMatchingKeys = (obj) => {
        const matches = [];
        const stack = [obj];
        while (stack.length > 0) {
          const current = stack.pop();
          if (current && typeof current === "object") {
            for (const key in current) {
              if (keysToFilter.includes(key) && !matches.includes(key)) {
                matches.push(key);
              }
              stack.push(current[key]);
            }
          }
        }

        return matches;
      };

      const matchedKeys = data.flatMap((item) => findMatchingKeys(item));
      setAvailableKeys([...new Set(matchedKeys)]);
    }
  }, [data]);

  const handleFilterChange = (e) => {
    handleLoading(true);

    const selectedKey = e.target.value;
    setFilterKey(selectedKey);

    // Reorder data to place filtered items at the top
    if (selectedKey) {
      const sortedData = [
        ...data.filter(
          (item) => item[selectedKey] !== undefined && item[selectedKey]
        ), // Filtered items
        ...data.filter(
          (item) => item[selectedKey] === undefined || !item[selectedKey]
        ), // Remaining items
      ];

      onFilter(sortedData);

      if (sortedData.length == 0) {
        toast.error("No Values Sorted");
      } else {
        toast.success("Values Sorted");
      }
      handleLoading(false);
    } else {
      onFilter(data); // Return full data if no filter is selected
      handleLoading(false);
    }
  };

  return (
    <div>
      <label htmlFor="filter" className="mr-2">
        Sort by:
      </label>
      <select
        id="filter"
        value={filterKey}
        onChange={handleFilterChange}
        className="border rounded p-2">
        <option value="">Select a field</option>
        {availableKeys.map((key) => (
          <option key={key} value={key}>
            {key}
          </option>
        ))}
      </select>
    </div>
  );
};

export default Sort;
