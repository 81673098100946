import { Button } from "@mui/material";
import axios from "axios";
import React from "react";
import { deleteChildCategory } from "../../../API/ChildCategoryApi";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { setLoading } from "../../../store/actions/actions";
import { useSelector } from "react-redux";

const DeleteChildCategory = (props) => {
  const dispatch = useDispatch();
  const userId = useSelector((state) => state.userId.userId);
  const handleLoading = (value) => {
    dispatch(setLoading(value));
  };
  const erase = () => {
    handleLoading(true);
    deleteChildCategory(props.id, userId)
      .then((response) => {
        if (response.status === true) {
          handleLoading(false);
          toast.success(`${response.message}`);
          setTimeout(() => {
            window.location.reload();
          }, 2000); // Delay reload by 2 seconds
        } else {
          handleLoading(false);
          toast.error(`${response.message}`);
        }
      })
      .catch((error) => {
        console.error("Error deleting category:", error);
      });
  };
  return (
    <Button onClick={() => erase()} variant={props.variant} color="error">
      Delete
    </Button>
  );
};

export default DeleteChildCategory;
