// ProductApi.js
import axios from "axios";
import { backendApiUrl } from "../constants";

const baseURL = backendApiUrl;

const ProductApi = axios.create({
  baseURL,
  headers: {
    "Content-Type": "application/json",
  },
});

// // Add an interceptor to include the token in the Authorization header for all requests
// ProductApi.interceptors.request.use(
//   (config) => {
//     const authToken = localStorage.getItem("token"); // Assuming you store the token in localStorage
//     if (authToken) {
//       config.headers.Authorization = `Bearer ${authToken}`;
//     }
//     return config;
//   },
//   (error) => {
//     return Promise.reject(error);
//   }
// );

// Define your product-related API endpoints
export const getProducts = async () => {
  try {
    const response = await ProductApi.get("/product");
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getUnverifiedProducts = async () => {
  try {
    const response = await ProductApi.get("/all/products");
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getProductById = async (id) => {
  try {
    const response = await ProductApi.get(`/product/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getProductByShopId = async (id) => {
  try {
    const response = await ProductApi.get(`/product/${id}/shop/?count=250`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getUnverifiedProductByShopId = async (id) => {
  try {
    const response = await ProductApi.get(`/product/${id}/shop/unverified`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getLikedProducts = async () => {
  try {
    const response = await ProductApi.get("/product/liked");
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getChildCategoryProducts = async (id) => {
  try {
    const response = await ProductApi.get(`/product/${id}/childcategory`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateProduct = async (id, productData) => {
  try {
    const response = await ProductApi.put(`/product/${id}`, productData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const verifyProduct = async (id, productData) => {
  try {
    const response = await ProductApi.put(`/product/${id}/verify`, productData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const createProduct = async (productData) => {
  try {
    const response = await ProductApi.post("/product", productData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteProduct = async (id, userId) => {
  try {
    const response = await ProductApi.delete(`/product/${id}`, {
      data: { userId },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const likeProduct = async (id) => {
  try {
    const response = await ProductApi.put(`/product/${id}/liking`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const unlikeProduct = async (id) => {
  try {
    const response = await ProductApi.put(`/product/${id}/unliking`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Add other product-related API endpoints as needed

export default ProductApi;
