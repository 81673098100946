import * as React from "react";
import "./SideBar.css";
import "boxicons";
import { useLocation, Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { clearAuthToken } from "../../../store/reducers/authSlice";
import { toast } from "react-toastify";

const Sidebar = ({ onLogout }) => {
  const dispatch = useDispatch();
  const useMediaQuery = (query) => {
    const [matches, setMatches] = useState(false);

    useEffect(() => {
      const media = window.matchMedia(query);
      if (media.matches !== matches) {
        setMatches(media.matches);
      }
      const listener = () => setMatches(media.matches);
      window.addEventListener("resize", listener);
      return () => window.removeEventListener("resize", listener);
    }, [matches, query]);

    return matches;
  };

  let menuItems = [
    {
      name: "Litchies",
      iconName: "sidebar",
      title: "extend",
    },
    {
      index: 0,
      name: "Home",
      iconName: "home",
      type: "solid",
      link: "*",
      title: "Home",
    },
    {
      index: 1,
      name: "New Requests",
      iconName: "store",
      type: "solid",
      link: "newRequests",
      title: "New Requests",
    },
    {
      index: 2,
      name: "All Banners",
      iconName: "carousel",
      type: "solid",
      link: "allBanners",
      title: "All Banners",
    },
    {
      index: 3,
      name: "All Offers",
      iconName: "discount",
      type: "solid",
      link: "allOffers",
      title: "All Offers",
    },
    {
      index: 4,
      name: "Categories",
      iconName: "category",
      type: "solid",
      link: "categories",
      title: "Categories",
    },
    {
      index: 5,
      name: "Customize Notifications",
      iconName: "bell-plus",
      type: "solid",
      link: "customNotification",
      title: "Customize Notifications",
    },
    {
      index: 6,
      name: "Reels",
      iconName: "play",
      type: "solid",
      link: "reels",
      title: "Reels",
    },
    {
      index: 7,
      name: "Global Assets",
      iconName: "image-add",
      type: "solid",
      link: "globalAssets",
      title: "Global Assets",
    },
    {
      index: 8,
      name: "Enquiries",
      iconName: "report",
      type: "solid",
      link: "enquiries",
      title: "Enquiries",
    },
    {
      name: "Log Out",
      iconName: "log-out",
      link: "#",
      color: "red",
      rotate: "180",
      title: "Log Out",
      onClick: () => {
        dispatch(clearAuthToken());
        toast.success("Logged out successfully!");
        if (onLogout) onLogout(); // Call parent logout handler if provided
      },
    },
  ];

  const [hovered, setHovered] = useState(null);
  const [active, setActive] = useState(1);
  const [animate, setAnimate] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const changeSmall = useMediaQuery("(max-height: 550px)");
  let delay = 1;

  useEffect(() => {
    setAnimate(true);
    let timer = setTimeout(() => setAnimate(false), delay * 1000);

    return () => {
      clearTimeout(timer);
    };
  }, [active, delay]);

  const currentLink = useLocation().pathname;

  return (
    <div className={`sidebar ${expanded ? "expanded" : "retracted"}`}>
      {menuItems.map((item, index) => {
        let middle = !(index === 0 || index === menuItems.length - 1);

        return (
          <div
            className={`boxicon-container ${
              expanded ? "expanded-boxicon-container" : ""
            }`}
            onMouseEnter={() => {
              if (middle) {
                setHovered(index);
              }
            }}
            onMouseLeave={() => {
              if (middle) {
                setHovered(null);
              }
            }}
            onClick={() => {
              if (middle) {
                setActive(index);
              }
              if (index === 0) {
                setExpanded(!expanded); // Toggle between expanded and retracted
              }
              if (item.onClick) {
                item.onClick(); // Call onClick function if defined
              }
            }}
            key={index}>
            <Link
              to={item.link}
              className={currentLink === item.link ? "active-Link active" : ""}>
              <box-icon
                class={`${middle ? "boxicon" : "first-and-last-trash-fix"}`}
                size={changeSmall ? "sm" : "md"}
                name={item.iconName}
                type={item.type}
                title={item.title}
                color={
                  hovered === index || currentLink === item.link
                    ? "#f9003b"
                    : "white"
                }
                animation={currentLink === item.link && animate ? "tada" : ""}
                rotate={item.rotate}></box-icon>
              {/* Conditionally render the name */}
              {expanded && (
                <p
                  className={`description show-description ${
                    currentLink === item.link && "active-description"
                  }`}>
                  {item.name}
                </p>
              )}
            </Link>
          </div>
        );
      })}
    </div>
  );
};

export default Sidebar;
