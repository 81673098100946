/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import empty from "../Elements/Images/empty1.gif";
// import ShopBannerCard from "./Cards/ShopBannerCard";
import { getShopBannerByShopId } from "../../API/ShopBannerApi";
import ShopBannerRow from "./ListItem/ShopBannerRow";

export default function BannerList() {
  const [banner, setBanner] = useState([]);
  const { shopId } = useParams();

  const fetchBanner = () => {
    getShopBannerByShopId(shopId)
      .then((response) => response.data)
      .then((data) => {
        // Sort banners by updatedAt (latest first)
        const sortedBanner = data.sort(
          (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
        );
        setBanner(sortedBanner);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  useEffect(() => {
    fetchBanner();
  }, []);

  return (
    <>
      <Box>
        <Typography
          variant="h3"
          align="center"
          fontWeight="600"
          marginTop="5vh">
          Shop Banners
        </Typography>
      </Box>
      {banner.length > 0 ? (
        <Box>
          <Box align="right" margin="20px">
            <Link to={`/admin/verifiedShopsList/addShopBanner/${shopId}`}>
              <Button variant="contained">Add Banner</Button>
            </Link>
          </Box>
          <TableContainer
            component={Paper}
            sx={{
              width: "86%",
              margin: "20px auto",
              marginLeft: "9%",
              boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
              borderRadius: "8px",
            }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="left">
                    <b>Banner Image</b>
                  </TableCell>
                  <TableCell align="left">
                    <b>Shop Name</b>
                  </TableCell>
                  <TableCell align="left">
                    <b>Actions</b>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {banner.map((banner) => (
                  <ShopBannerRow
                    key={banner._id}
                    name={banner.shopBanner}
                    shopId={banner.shopId._id}
                    shopName={banner.shopId.shopName}
                    isActive={banner.isActive}
                    id={banner._id}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      ) : (
        <Box
          display="inlineFlex"
          alignItems="top"
          height="100vh"
          justifyContent="center">
          <Box>
            <Box display="flex" justifyContent="center">
              <img src={empty} width="500px"></img>
            </Box>
            <Box align="center">
              <Link
                to={`/admin/verifiedShopsList/addShopBanner/${shopId}`}
                sx={{ textDecoration: "none" }}>
                <Button variant="contained" style={{ color: "white" }}>
                  Add Some
                </Button>
              </Link>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
}
