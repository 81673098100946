import React, { useState, useEffect } from "react";
import {
  TableRow,
  TableCell,
  Button,
  Modal,
  Box,
  Typography,
  IconButton,
  Switch,
  FormControl,
  FormLabel,
  FormGroup,
  FormControlLabel,
} from "@mui/material";
import axios from "axios";
import "boxicons"; // Ensure boxicons are installed and imported
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useDispatch } from "react-redux";
import { setLoading } from "../../../store/actions/actions";
import { updateOffer } from "../../../API/OfferApi";

const OfferRow = ({
  imageUrl,
  offerText,
  offerStart,
  offerEnd,
  linkUrl,
  offerCode,
  isAll,
  shopId,
  shopName,
  offerId,
  isDisplayed,
  isVerified,
}) => {
  const [checked, setChecked] = useState(isDisplayed || false);
  const [verified, setVerified] = useState(isVerified || false);
  const [open, setOpen] = useState(false); // State for modal visibility
  const dispatch = useDispatch();
  let startDate = new Date(offerStart);
  let endDate = new Date(offerEnd);
  // Sync states when props change
  useEffect(() => {
    setChecked(isDisplayed);
    setVerified(isVerified);
  }, [isDisplayed, isVerified]);

  const handleOpen = (e) => {
    e.stopPropagation();
    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  const handleDelete = async () => {
    try {
      await axios.delete(`https://server.atf-labs.com:3000/offers/${offerId}`);
      toast.success("Offer deleted successfully!");
      handleClose();
      setTimeout(() => {
        window.location.reload();
      }, 2000); // Delay reload by 2 seconds
    } catch (error) {
      toast.error("Error deleting offer: " + error.message);
    }
  };

  const handleLoading = (value) => dispatch(setLoading(value));

  const onSubmit = async (e, type) => {
    const value = e.target.checked;
    handleLoading(true);

    if (type === "displayed") setChecked(value);
    if (type === "verified") setVerified(value);

    const formData = new FormData();
    formData.append(type === "displayed" ? "isPriority" : "isVerified", value);

    try {
      const response = await updateOffer(offerId, formData);
      handleLoading(false);

      if (response.status === true) toast.success(`${response.message}`);
      else toast.error(`${response.message}`);
    } catch (error) {
      handleLoading(false);
      toast.error("Error updating offer: " + error.message);

      // Rollback state if the API call fails
      if (type === "displayed") setChecked(!value);
      if (type === "verified") setVerified(!value);
    }
  };

  const handleCopyClick = () => {
    navigator.clipboard.writeText(offerId);
    toast.success("Offer Id code copied!");
  };

  return (
    <>
      <TableRow sx={{ cursor: "pointer" }}>
        {/* Offer Image */}
        <TableCell>
          <img src={imageUrl || "/no-image.jpg"} alt="Offer" height={100} />
        </TableCell>

        {/* Shop Id */}
        {isAll && <TableCell>{shopName}</TableCell>}
        {/* Offer Text */}
        {/* <TableCell>{offerText}</TableCell> */}
        <TableCell>
          {endDate.toDateString()} - {endDate.toDateString()}
        </TableCell>

        {/* Offer ID */}
        <TableCell onClick={(e) => e.stopPropagation()}>
          <span
            style={{ cursor: "pointer", color: "blue" }}
            onClick={handleCopyClick}>
            {offerId}
          </span>
        </TableCell>

        {/* Controls */}
        <TableCell>
          <Link
            to={`/admin/verifiedShopsList/editOffers/${shopId}/${offerId}`}
            style={{ textDecoration: "none", color: "inherit" }}>
            <IconButton title="View">
              <VisibilityIcon sx={{ color: "blue" }} />
            </IconButton>
          </Link>

          <FormControl component="fieldset" sx={{ mb: 2 }}>
            <FormGroup row>
              <FormControlLabel
                control={
                  <Switch
                    checked={checked}
                    onChange={(e) => onSubmit(e, "displayed")}
                    color="primary"
                    inputProps={{ "aria-label": "Is Displayed" }}
                  />
                }
                label="Is Displayed"
                labelPlacement="top"
              />

              <FormControlLabel
                control={
                  <Switch
                    checked={verified}
                    onChange={(e) => onSubmit(e, "verified")}
                    color="primary"
                    inputProps={{ "aria-label": "Is Verified" }}
                  />
                }
                label="Is Verified"
                labelPlacement="top"
              />
            </FormGroup>
          </FormControl>

          <IconButton onClick={handleOpen} color="error">
            <box-icon
              name="x"
              type="regular"
              color="red"
              title="Delete offer"
            />
          </IconButton>
        </TableCell>
      </TableRow>

      {/* Modal for Delete Confirmation */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="delete-confirmation-title"
        aria-describedby="delete-confirmation-description">
        <Box sx={modalStyle}>
          <Typography
            id="delete-confirmation-title"
            variant="h6"
            component="h2">
            Confirm Delete
          </Typography>
          <Typography id="delete-confirmation-description" sx={{ mt: 2 }}>
            Are you sure you want to delete this offer? This action cannot be
            undone.
          </Typography>
          <Box sx={{ mt: 3, display: "flex", justifyContent: "flex-end" }}>
            <Button onClick={handleClose} variant="outlined" sx={{ mr: 2 }}>
              Cancel
            </Button>
            <Button onClick={handleDelete} variant="contained" color="error">
              Delete
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

// Modal styling
const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "8px",
};

export default OfferRow;
