import React, { useState } from "react";
import {
  TableRow,
  TableCell,
  IconButton,
  Box,
  Typography,
  Avatar,
  FormControl,
  FormGroup,
  FormControlLabel,
  Switch,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DOMPurify from "dompurify";
import { Link } from "react-router-dom";
import { deleteProduct, updateProduct } from "../../../API/ProductApi";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "../../../store/actions/actions";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import NotificationAddIcon from "@mui/icons-material/NotificationAdd";

const ProductRow = ({
  id,
  imgUrl,
  Product_Name,
  Product_Desc,
  Price,
  isPriority,
  mrp,
  shopId,
}) => {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const userId = useSelector((state) => state.userId.userId);
  const [checked, setChecked] = useState(isPriority || false);

  const handleLoading = (value) => {
    dispatch(setLoading(value));
  };

  const onSubmit = (e) => {
    setChecked(e.target.checked);
    handleLoading(true);

    // const data = { isPriority: e.target.checked };
    const data = new FormData();
    data.append("isPriority", e.target.checked);
    // Update the shop status
    updateProduct(id, data)
      .then((res) => {
        if (res.status === true) {
          handleLoading(false);
          toast.success("Product Updated");
        } else {
          handleLoading(false);
          toast.error("Error Updating Product");
        }
      })
      .catch((error) => {
        handleLoading(false);
        console.error("Error updating product:", error);
      });
  };

  const erase = async () => {
    handleLoading(true);
    deleteProduct(id, userId)
      .then((res) => {
        if (res.status === false) {
          handleLoading(false);
          toast.error(res.message);
        } else {
          handleLoading(false);
          toast.success(res.message);
          setTimeout(() => {
            window.location.reload();
          }, 2000); // Delay reload by 2 seconds
        }
      })
      .catch((error) => {
        console.error("Error deleting product:", error);
      });
  };

  const truncateDescription = (description, limit) => {
    if (description.length <= limit || description.length <= 30) {
      return description;
    }
    return description.substring(0, limit) + "...";
  };

  const createMarkup = (html) => ({
    __html: DOMPurify.sanitize(html),
  });

  return (
    <TableRow>
      {/* Image */}
      <TableCell>
        {typeof imgUrl === "string" ? (
          <Avatar
            src={imgUrl || "/no-image.jpg"}
            alt={Product_Name || "No Image"}
            sx={{ width: 70, height: 70, borderRadius: "0" }}
          />
        ) : (
          <Avatar
            src="/no-image.jpg"
            alt="No Image"
            sx={{ width: 70, height: 70 }}
          />
        )}
      </TableCell>

      {/* Product Name */}
      <TableCell>
        <Typography
          sx={{
            fontWeight: "700",
            fontSize: "16px",
            fontFamily: "Inter",
            lineHeight: "30px",
            color: "grey",
          }}>
          {truncateDescription(Product_Name, 20)}
        </Typography>
      </TableCell>

      {/* Description */}
      <TableCell>
        <Box maxHeight="8vh" overflow="hidden">
          <Typography>
            <div
              dangerouslySetInnerHTML={createMarkup(
                truncateDescription(Product_Desc, 60)
              )}
            />
          </Typography>
        </Box>
      </TableCell>

      {/* Price */}
      <TableCell>
        <Typography>
          {Price > 0 ? (
            <>
              <strike>{mrp}</strike> <b>{Price}</b>
            </>
          ) : (
            <b>{Price}</b>
          )}
        </Typography>
      </TableCell>

      {/* Actions */}

      <TableCell>
        <Box display="flex" alignItems="center">
          <Link
            to={`/admin/verifiedShopsList/showProducts/${shopId}/${id}`}
            style={{ textDecoration: "none", color: "inherit" }}>
            <IconButton title="View">
              <VisibilityIcon sx={{ color: "blue" }} />
            </IconButton>
          </Link>
          <FormControl component="fieldset" sx={{ mb: 2 }}>
            <FormGroup row>
              <FormControlLabel
                control={
                  <Switch
                    checked={checked}
                    onChange={(e) => onSubmit(e)}
                    color="primary"
                    inputProps={{ "aria-label": "Is Priority" }}
                  />
                }
                label="Is Premium"
                labelPlacement="top"
              />
            </FormGroup>
          </FormControl>
          <IconButton title="Delete" onClick={erase}>
            <DeleteIcon sx={{ color: "red" }} />
          </IconButton>
          {/* <IconButton title="Send Notification" disabled>
            <NotificationAddIcon sx={{ color: "grey" }} />
          </IconButton> */}
        </Box>
      </TableCell>
    </TableRow>
  );
};

export default ProductRow;
