import React, { useEffect, useState, useRef } from "react";
import { Box } from "@mui/system";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { getAllReels, deleteReels } from "../../../API/ReelsApi";
import { useStyles } from "./Style.js";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { setLoading } from "../../../store/actions/actions.js";

function Reels() {
  const classes = useStyles();
  const [reels, setReels] = useState([]);
  const [currentPlaying, setCurrentPlaying] = useState(null);
  const videoRefs = useRef([]);
  const dispatch = useDispatch();

  const handleLoading = (value) => {
    dispatch(setLoading(value));
  };
  useEffect(() => {
    fetchData();
  }, []);

  const handleScroll = (e) => {
    e.scrollBy(100, 100, "smooth");
  };

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.5, // Trigger when 50% of the element is visible
    };

    const handleIntersection = (entries) => {
      entries.forEach((entry) => {
        const video = entry.target;

        if (entry.isIntersecting) {
          video.play();
          setCurrentPlaying(video);
        } else {
          video.pause();
        }
      });
    };

    // Initialize Intersection Observer
    const observer = new IntersectionObserver(handleIntersection, options);

    // Observe each video element
    videoRefs.current.forEach((video) => {
      observer.observe(video);
    });

    return () => {
      observer.disconnect();
    };
  }, [reels]);

  const fetchData = () => {
    handleLoading(true);
    getAllReels()
      .then((response) => {
        if (response.status === true) {
          handleLoading(false);
          setReels(response.data);
        } else {
          handleLoading(false);
          toast.error(response.message);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const handleDelete = (id) => {
    handleLoading(true);
    deleteReels(id)
      .then(() => {
        handleLoading(false);
        toast.success("Reel deleted successfully");
        setReels(reels.filter((reel) => reel._id !== id));
      })
      .catch((error) => {
        handleLoading(false);
        toast.error(error.message);
        console.error("Error deleting reel:", error);
      });
  };

  const handlePlay = (e) => {
    e.preventDefault();
    const video = e.target;

    if (currentPlaying && currentPlaying !== video) {
      currentPlaying.pause();
    }

    if (video.paused) {
      video.play();
      setCurrentPlaying(video);
    } else {
      video.pause();
      setCurrentPlaying(null);
    }
  };

  return (
    <div
      style={{
        display: "grid",
        justifyContent: "center",
        width: "100%",
        backgroundColor: "black",
        padding: "20px",
      }}>
      {reels.map((reel, index) => (
        <Box
          key={reel._id}
          position="relative"
          style={{
            width: "100%",
            maxWidth: "520px",
            margin: "10px",
            backgroundColor: "black",
          }}>
          <IconButton
            onClick={() => handleDelete(reel._id)}
            sx={{
              position: "absolute",
              top: "10px",
              right: "10px",
              zIndex: 1,
              backgroundColor: "white",
              color: "red",
            }}>
            <CloseIcon />
          </IconButton>
          <video
            ref={(el) => (videoRefs.current[index] = el)}
            className={classes.videoStyles}
            onClick={handlePlay}
            onScroll={handleScroll}
            controls
            type="video/mp4"
            style={{ width: "100%", height: "auto" }}
            autoPlay={false} // Start with autoPlay set to false
            muted>
            <source src={reel.url} type="video/mp4" />
          </video>
        </Box>
      ))}
    </div>
  );
}

export default Reels;
