// EnquiryApi.js
import axios from "axios";
import { backendApiUrl } from "../constants";

const baseURL = backendApiUrl;

const EnquiryApi = axios.create({
  baseURL,
  headers: {
    "Content-Type": "application/json"
  }
});

// Add an interceptor to include the token in the Authorization header for all requests
EnquiryApi.interceptors.request.use(
  (config) => {
    const authToken = localStorage.getItem("token"); // Assuming you store the token in localStorage
    if (authToken) {
      config.headers.Authorization = `Bearer ${authToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Define your enquiry-related API endpoints
export const getEnquiries = async () => {
  try {
    const response = await EnquiryApi.get("/enquiry");
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getEnquiryById = async (id) => {
  try {
    const response = await EnquiryApi.get(`/enquiry/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getEnquiryByShopId = async (id) => {
  try {
    const response = await EnquiryApi.get(`/enquiry/${id}/shop`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getEnquiryByUserId = async (id) => {
  try {
    const response = await EnquiryApi.get(`/enquiry/${id}/user`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const createEnquiry = async (enquiryData) => {
  try {
    const response = await EnquiryApi.post("/enquiry", enquiryData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Add other enquiry-related API endpoints as needed

export default EnquiryApi;
