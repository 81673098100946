import React, { useState } from "react";
import {
  TableRow,
  TableCell,
  IconButton,
  Avatar,
  Box,
  FormControl,
  FormGroup,
  FormControlLabel,
  Switch,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import NotificationAddIcon from "@mui/icons-material/NotificationAdd";
import Warning from "../Modal/Warning";
import MoreVertItems from "../MoreVertItems";
import { Link } from "react-router-dom";
import { updateShop } from "../../../API/ShopApi";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { setLoading } from "../../../store/actions/actions";

const ShopItem = ({
  id,
  imgUrl,
  Shop_Name,
  Karta_Name,
  Mobile,
  isPriority,
}) => {
  const [open, setOpen] = useState(false);
  const [checked, setChecked] = useState(isPriority || false);

  const dispatch = useDispatch();
  const handleLoading = (value) => {
    dispatch(setLoading(value));
  };
  const handleDeleteClick = () => {
    setOpen(true);
  };

  const onSubmit = (e) => {
    setChecked(e.target.checked);
    handleLoading(true);

    // const data = { isPriority: e.target.checked };
    const data = new FormData();
    data.append("isPriority", e.target.checked);
    // Update the shop status
    updateShop(id, data)
      .then((res) => {
        if (res.status === true) {
          handleLoading(false);
          toast.success("Shop Updated");
        } else {
          handleLoading(false);
          toast.error("Error Updating Shop");
        }
      })
      .catch((error) => {
        handleLoading(false);
        console.error("Error updating shop:", error);
      });
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <TableRow>
        <TableCell>
          <Avatar
            src={imgUrl || "/no-image.jpg"}
            alt={Shop_Name || "No Image"}
            sx={{ width: 60, height: 60 }}
          />
        </TableCell>
        <TableCell>{Shop_Name}</TableCell>
        <TableCell>{Karta_Name}</TableCell>
        <TableCell>{Mobile}</TableCell>

        <TableCell>
          <Box display="flex" alignItems="center">
            <Link
              to={`verifiedShopsList/shopDetails/${id}`}
              style={{ textDecoration: "none", color: "inherit" }}>
              <IconButton title="View">
                <VisibilityIcon sx={{ color: "blue" }} />
              </IconButton>
            </Link>
            <FormControl component="fieldset" sx={{ mb: 2 }}>
              <FormGroup row>
                <FormControlLabel
                  control={
                    <Switch
                      checked={checked}
                      onChange={(e) => onSubmit(e)}
                      color="primary"
                      inputProps={{ "aria-label": "Is Priority" }}
                    />
                  }
                  label="Is Premium"
                  labelPlacement="top"
                />
              </FormGroup>
            </FormControl>
            <IconButton title="Delete" onClick={handleDeleteClick}>
              <DeleteIcon sx={{ color: "red" }} />
            </IconButton>
            {/* <IconButton title="Send Notification" disabled>
              <NotificationAddIcon sx={{ color: "grey" }} />
            </IconButton> */}
            <MoreVertItems id={id} />
          </Box>
        </TableCell>
      </TableRow>

      <Warning open={open} onClose={handleClose} id={id} component="Shop" />
    </>
  );
};

export default ShopItem;
