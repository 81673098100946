/* eslint-disable react-hooks/rules-of-hooks */
// NotificationApi.js
import axios from "axios";
import { backendApiUrl } from "../constants";
import { toast } from "react-toastify";

const baseURL = backendApiUrl;

const CustomNotificationApi = axios.create({
  baseURL,
});

// // Add an interceptor to include the token in the Authorization header for all requests
// NotificationApi.interceptors.request.use(
//   (config) => {
//     const authToken = store.getState().auth.authToken;
//     if (authToken) {
//       config.headers.Authorization = `Bearer ${authToken}`;
//     }
//     return config;
//   },
//   (error) => {
//     return Promise.reject(error);
//   }
// );

// Define your notification-related API endpoint for sending admin notifications
export const sendNotificationAdmin = async (notificationData) => {
  try {
    const response = await CustomNotificationApi.post(
      "/sns/sendnotificationAdmin",
      notificationData
    );
    toast.success("Notification sent successfully");
    return response.data;
  } catch (error) {
    toast.error("Failed to send notification");
    throw error;
  }
};

// Add other notification-related API endpoints as needed

export default CustomNotificationApi;
