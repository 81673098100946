import React from "react";
import { Box, Typography } from "@mui/material";
import { motion } from "framer-motion";

export default function ComingSoon() {
  return (
    <Box
      sx={{
        height: "100vh",
        backgroundColor: "#0b121a",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}>
      <motion.img
        src="/Logo1.png" // Replace with your image URL
        alt="Coming Soon"
        style={{
          width: "10%",
          height: "auto",
          marginBottom: "20px",
        }}
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1 }}
      />
      <motion.div
        initial={{ opacity: 0, scale: 0.8 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ delay: 0.5, duration: 1 }}>
        <Typography
          variant="h3"
          sx={{
            color: "rgb(249, 0, 59)",
            fontWeight: 600,
          }}>
          Coming Soon
        </Typography>
      </motion.div>
    </Box>
  );
}
