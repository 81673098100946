import axios from "axios";
import { backendApiUrl } from "../constants";

const baseURL = backendApiUrl;

const OtpAuthApi = axios.create({
  baseURL,
  headers: {
    "Content-Type": "application/json",
  },
});

// Add an interceptor to include the token in the Authorization header for all requests (if needed)
OtpAuthApi.interceptors.request.use(
  (config) => {
    const authToken = localStorage.getItem("token"); // Assuming you store the token in localStorage
    if (authToken) {
      config.headers.Authorization = `Bearer ${authToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Define your admin login API endpoint
export const adminLogin = async (credentials) => {
  try {
    const response = await OtpAuthApi.post("/auth/adminlogin", credentials);
    return response.data; // This should return the token or user details upon successful login
  } catch (error) {
    throw error; // Error handling can be extended as needed
  }
};

// Export the OtpAuthApi instance if needed (for other API calls)
export default OtpAuthApi;
