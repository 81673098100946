/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import {
  Box,
  Button,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import empty from "../Elements/Images/empty1.gif";
// import UnVerifiedProductCard from "./Cards/UnVerifiedProductCard";
import { getUnverifiedProductByShopId } from "../../API/ProductApi";
import { Paper, TableBody, TableCell } from "@material-ui/core";
import UnVerifiedProductRow from "./ListItem/UnVerifiedProductRow";
export default function UnVerifiedProduct() {
  const [products, setProducts] = useState([]);
  const shopId = useParams();
  const fetchProducts = () => {
    console.log(shopId);

    getUnverifiedProductByShopId(shopId.shopId)
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        // Sorting products by updatedAt (latest first)
        const sortedProducts = data.sort(
          (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
        );

        setProducts(sortedProducts);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  return (
    <>
      <Typography variant="h3" align="center" fontWeight="600" marginTop="5vh">
        Un-Verified Products
      </Typography>
      {products.length > 0 ? (
        <>
          <TableContainer
            component={Paper}
            sx={{
              width: "86%",
              margin: "20px auto",
              marginLeft: "9%",
              boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
              borderRadius: "8px",
            }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <b>Image</b>
                  </TableCell>
                  <TableCell>
                    <b>Product Name</b>
                  </TableCell>
                  <TableCell>
                    <b>Description</b>
                  </TableCell>
                  <TableCell>
                    <b>Price</b>
                  </TableCell>
                  <TableCell>
                    <b>Actions</b>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {products.map((product) => (
                  <UnVerifiedProductRow
                    key={product._id}
                    imgUrl={product.thumbnailURL}
                    Product_Name={product.name}
                    Product_Desc={product.desc}
                    Price={product.price}
                    id={product._id}
                    shopId={shopId}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      ) : (
        <Box
          display="inlineFlex"
          alignItems="top"
          height="100vh"
          justifyContent="center">
          <Box>
            <Typography
              align="center"
              mb="3vh"
              variant="h3"
              style={{ zIndex: "3" }}></Typography>
            <Box display="flex" justifyContent="center">
              <img src={empty} width="500px"></img>
            </Box>
            <Box align="center">
              <Link
                to={`/#/admin/verifiedShopsList/addProduct/${shopId}`}
                sx={{}}>
                <Button variant="contained">Add Some</Button>
              </Link>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
}
