import React, { useEffect } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { Button, TextField, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import {
  setAuthToken,
  clearAuthToken,
} from "../../../store/reducers/authSlice";
import "./LogIn.css";
import { toast } from "react-toastify";
import { setLoading, setUserId } from "../../../store/actions/actions";
import { adminLogin } from "../../../API/OtpAuthApi"; // Import your api

const theme = createTheme({
  palette: {
    primary: { main: "#1565c0" },
    secondary: { main: "#f9003b" },
  },
});

const SignIn = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [creds, setCreds] = React.useState({ mobile: "", otp: "" });
  const [otpSent, setOtpSent] = React.useState();
  const [otp, setOtp] = React.useState("");
  let isAuthUser = false;

  const handleChange = (e) => {
    const value = e.target.value;
    setCreds({ ...creds, [e.target.name]: value });
  };

  const handleLoading = (value) => {
    dispatch(setLoading(value));
  };

  const sendOtp = async (event) => {
    event.preventDefault();
    handleLoading(true);

    try {
      // Call your OTP sending API method here
      const response = await adminLogin({ mobile: Number(creds.mobile) });
      if (response.status === true) {
        toast.success("OTP sent to your mobile!");
        console.log(response);
        setOtpSent(response.data.OTP); // Set state to show OTP input
      } else {
        toast.error("Error sending OTP!");
      }
    } catch (error) {
      toast.error("Failed to send OTP. Please try again.");
    } finally {
      handleLoading(false);
    }
  };

  const handleOtpVerification = async (event) => {
    event.preventDefault();
    handleLoading(true);
    console.log(otp, otpSent);
    try {
      // Here you handle the verification, calling another API if needed
      // Adjust this to your actual verification API logic
      if (otp == Number(otpSent)) {
        // This won't be valid in real implementation
        handleLoading(false);
        toast.success("Logged in successfully!");
        sessionStorage.setItem("isAuthenticated", "true");
        dispatch(setAuthToken(true));
        dispatch(setUserId(creds.mobile));
        navigate("/admin/*");
      } else {
        handleLoading(false);
        toast.error("Incorrect OTP!");
      }
    } catch (error) {
      toast.error("Error during OTP verification. Please try again.");
      handleLoading(false);
    }
  };

  useEffect(() => {
    let isReloading = false;

    const handleBeforeUnload = (event) => {
      sessionStorage.setItem("isReloading", "true");
      setTimeout(() => {
        sessionStorage.removeItem("isReloading");
      }, 10);
    };

    const handleLoad = () => {
      if (sessionStorage.getItem("isReloading")) {
        isReloading = true;
      }
    };

    const handleUnload = () => {
      if (!isReloading) {
        // handleLogout();
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    window.addEventListener("load", handleLoad);
    window.addEventListener("unload", handleUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      window.removeEventListener("load", handleLoad);
      window.removeEventListener("unload", handleUnload);
    };
  }, [dispatch]);

  return (
    <ThemeProvider theme={theme}>
      <div className="background">
        <Typography
          variant="h3"
          textAlign="center"
          color="white"
          sx={{ pt: 6, pl: 4 }}>
          Welcome To <span style={{ color: "#f9003b" }}>Litchies</span> Admin
        </Typography>
        <div className="wrapper">
          <div className="SideImage"></div>
          <div className="LoginForm">
            <h2 className="h3">{otpSent ? "ENTER OTP" : "LOGIN"}</h2>
            <div className="Profile"></div>
            {!otpSent ? (
              <>
                <div className="group">
                  <TextField
                    type="number"
                    size="30"
                    id="mobile"
                    className="input"
                    label="Mobile"
                    variant="standard"
                    color="secondary"
                    name="mobile"
                    onChange={handleChange}
                    required
                  />
                </div>
                <Button
                  type="submit"
                  className="btn"
                  color="secondary"
                  variant="contained"
                  onClick={sendOtp}>
                  <span>SEND OTP</span>
                </Button>
              </>
            ) : (
              <>
                <div className="group">
                  <TextField
                    type="number"
                    className="input"
                    id="otp"
                    name="otp"
                    label="OTP"
                    value={otp}
                    variant="standard"
                    color="secondary"
                    onChange={(e) => setOtp(e.target.value)}
                    required
                  />
                </div>
                <Button
                  type="submit"
                  className="btn"
                  color="secondary"
                  variant="contained"
                  onClick={handleOtpVerification}>
                  <span>VERIFY OTP</span>
                </Button>
              </>
            )}
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
};

export default SignIn;
