import * as React from "react";
import Box from "@mui/material/Box";
import { TextField, Button } from "@mui/material";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { createProductCategory } from "../../../../../API/CategoryApi";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { setLoading } from "../../../../../store/actions/actions";

const AddCategory = (props) => {
  const [state, setState] = useState({
    name: "",
    imageURLs: "",
  });
  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = useForm();
  const [img, setImg] = React.useState({ preview: "", raw: "" });
  const dispatch = useDispatch();

  const handleLoading = (value) => {
    dispatch(setLoading(value));
  };

  const onImageChange = (e) => {
    if (e.target.files.length && e.target.files[0].size <= 3 * 1024 * 1024) {
      const file = e.target.files[0];
      const fileName = file.name.replace(/\s+/g, ""); // Remove white spaces

      // Create a new File object with the cleaned file name
      const cleanedFile = new File([file], fileName, { type: file.type });

      setImg({
        preview: URL.createObjectURL(cleanedFile),
        raw: cleanedFile,
      });
    } else {
      toast.warning("Image is too large, please keep it below 3MB");
    }
  };

  const handleChange = (e) => {
    const value = e.target.value;
    setState({
      ...state,
      [e.target.name]: value,
    });
  };

  const onSubmit = async (data) => {
    handleLoading(true);
    try {
      const formData = new FormData();
      formData.append("img", img.raw);
      formData.append("name", data.name);
      console.log(formData);
      const response = await createProductCategory(formData);
      if (response.status === true) {
        handleLoading(false);
        toast.success(`${response.message}`);
        setTimeout(() => {
          window.location.reload();
        }, 2000); // Delay reload by 2 seconds
      } else {
        handleLoading(false);
        toast.error(`${response.message}`);
        setTimeout(() => {
          window.location.reload();
        }, 2000); // Delay reload by 2 seconds
      }
    } catch (error) {
      toast.error("Error submitting form:", error);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <React.Fragment>
          <h2 align="center">Add a Category</h2>
          <TextField
            fullWidth
            name="name"
            label="Enter Category Name"
            onChange={(e) => {
              handleChange(e);
              setValue("name", e.target.value); // Setting value using setValue
            }}
            sx={{ marginY: 1 }}
            {...register("name", {
              required: "Category name is required",
              validate: (value) =>
                value.trim() === "" ? "Field cannot be empty" : undefined,
            })} // Registering the field
            error={!!errors.name} // Show error state when there's an error
            helperText={errors.name?.message} // Display the error message
          />
          <label style={{ marginBlock: "1vh" }}>Add Image(s)</label>
          <Box>
            <input multiple type="file" onChange={onImageChange} />
            <img
              src={img.preview}
              width="60px"
              height="60px"
              style={{ borderRadius: "55px" }}
              alt=""
            />
          </Box>

          <div
            style={{
              marginTop: "10px",
              display: "flex",
              justifyContent: "space-around",
            }}>
            <Button variant="contained" type="submit">
              Save
            </Button>
          </div>
        </React.Fragment>
      </form>
    </>
  );
};
export default AddCategory;
