export const backendApiUrl = "https://server.atf-labs.com:3000";
export const MapPickerApiToken = "AIzaSyBrCu57oEymo7VK-gTCouW3cdIGyv3Mmt4";
export const SideBarWidth = "60px";
export const User = "admin";
export const Password = "D@rk57ON";
export const Users = [
  // { userName: "Harsha", password: "Harsha@22284" },
  // { userName: "Anuj", password: "Anuj@94316" },
  // { userName: "Neha", password: "Neha@00921" },
  { userName: "Rudra", password: "Rudra@12901" },
];
