import React from "react";
import {
  TableCell,
  TableRow,
  IconButton,
  FormControl,
  FormGroup,
  FormControlLabel,
  Switch,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { deleteShopBanner, updateShopBanner } from "../../../API/ShopBannerApi";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { setLoading } from "../../../store/actions/actions";

const ShopBannerRow = (props) => {
  const { name, shopId, id, isActive, shopName } = props;
  const imgUrl = name || "/no-image.jpg";
  const dispatch = useDispatch();
  const [checked, setChecked] = React.useState(isActive);

  const handleLoading = (value) => {
    dispatch(setLoading(value));
  };

  const erase = async () => {
    handleLoading(true);
    deleteShopBanner(id)
      .then((res) => {
        if (res.status === true) {
          handleLoading(false);
          toast.success("Banner Deleted");
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        } else {
          handleLoading(false);
          toast.error("Error Deleting Banner");
        }
      })
      .catch((error) => {
        handleLoading(false);
        console.error("Error deleting banner:", error);
      });
  };

  const onSubmit = (e, field) => {
    setChecked(e.target.checked);
    handleLoading(true);
    const data = {
      isActive: e.target.checked,
    };
    // Update the banner status
    updateShopBanner(id, data)
      .then((res) => {
        if (res.status === true) {
          handleLoading(false);
          toast.success("Banner Updated");
        } else {
          handleLoading(false);
          toast.error("Error Updating Banner");
        }
      })
      .catch((error) => {
        handleLoading(false);
        console.error("Error updating banner:", error);
      });
  };

  return (
    <TableRow>
      <TableCell align="left">
        <img src={imgUrl} alt="Shop Banner" width="250" height="150" />
      </TableCell>
      <TableCell align="left">{shopName}</TableCell>
      <TableCell align="left">
        <FormControl component="fieldset" sx={{ mb: 2 }}>
          <FormGroup row>
            <FormControlLabel
              control={
                <Switch
                  checked={checked}
                  onChange={(e) => onSubmit(e, "displayed")}
                  color="primary"
                  inputProps={{ "aria-label": "Is Displayed" }}
                />
              }
              label="Is Active"
              labelPlacement="top"
            />
          </FormGroup>
        </FormControl>

        <IconButton onClick={erase} color="error">
          <CloseIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

export default ShopBannerRow;
