import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { TextField, Typography } from "@mui/material";

export default function MaxWidthDialog(props) {
  const { variantsOpen, setVariantsOpen, setVariants, variants } = props;
  const [variant, setVariant] = React.useState([]);
  const handleClose = () => {
    setVariants(variant);
    setVariantsOpen(false);
  };

  // const onImageChange1 = (e) => {
  //   const selectedFiles = Array.from(e.target.files);
  //   const imageObjects = selectedFiles.map((file) => ({
  //     preview: URL.createObjectURL(file),
  //     raw: file
  //   }));
  //   setProdImg(imageObjects);
  // };

  const [state, setState] = React.useState({
    productColor: "",
    productSize: "",
    productPrice: "",
  });

  const saveVariant = () => {
    const newVariant = {
      ...state,
      id: variant.length + 1, // Generate unique ID (simple increment here)
    };
    setVariant((prevVariants) => [...prevVariants, newVariant]);
    setState({
      productColor: "",
      productSize: "",
      productPrice: "",
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
    console.log(state);
  };

  return (
    <React.Fragment>
      <Dialog
        fullWidth={true}
        open={variantsOpen}
        onClose={handleClose}
        style={{ textAlign: "center" }}>
        <DialogTitle>Add Variants</DialogTitle>
        <DialogContent>
          <DialogContentText>
            You can add multiple variants here.
          </DialogContentText>
          <Box
            noValidate
            component="form"
            sx={{
              display: "flex",
              flexDirection: "column",
              m: "auto",
              width: "fit-content",
            }}>
            <TextField
              label="Color"
              name="productColor"
              value={state.productColor}
              style={{ marginBlock: "10px" }}
              onChange={handleChange}
            />
            <TextField
              label="Size"
              name="productSize"
              value={state.productSize}
              style={{ marginBlock: "10px" }}
              onChange={handleChange}
            />
            <TextField
              label="Price"
              style={{ marginBlock: "10px" }}
              name="productPrice"
              value={state.productPrice}
              onChange={handleChange}
            />

            <Typography>{variant.length}</Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={
              variant.length > 0 ||
              (state.productColor && state.productPrice && state.productSize)
                ? false
                : true
            }
            onClick={() => saveVariant()}>
            Save
          </Button>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
