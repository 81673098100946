/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Box } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";

const ReactDnD = (props) => {
  const { img, setImg } = props;

  const onDragEnd = (result) => {
    if (!result.destination) return; // Dragged outside the droppable area

    const startIndex = result.source.index;
    const endIndex = result.destination.index;

    const newImages = Array.from(img);
    const [removed] = newImages.splice(startIndex, 1);
    newImages.splice(endIndex, 0, removed);

    setImg(newImages); // Update the parent state with the new order
  };

  const imgDelete = (index) => {
    const newImageArray = img.filter((_, i) => i !== index);
    setImg(newImageArray);
  };

  useEffect(() => {
    console.log(img);
  }, [img]);

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="image-list" direction="horizontal">
        {(provided) => (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            style={{
              display: "flex",
              justifyContent: "flex-start",
              width: "500px",
              left: "10%",
              overflowX: "auto"
            }}>
            {img.length > 0 &&
              img.map((src, index) => (
                <Draggable
                  key={index}
                  draggableId={index.toString()}
                  index={index}>
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}>
                      <Box mx="1vh" justifyContent="center" display="flex">
                        <div
                          style={{
                            position: "relative",
                            display: "block",
                            margin: "10px"
                          }}>
                          <CloseIcon
                            style={{
                              position: "absolute",
                              top: "0",
                              right: "0",
                              color: "red",
                              cursor: "pointer",
                              backgroundColor: "white",
                              borderRadius: "50%",
                              padding: "2px"
                            }}
                            title="Delete"
                            onClick={() => imgDelete(index)}
                          />
                          <img width="200px" src={src} alt="ProductImage" />
                        </div>
                      </Box>
                    </div>
                  )}
                </Draggable>
              ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default ReactDnD;
