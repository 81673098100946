import React, { useEffect, useState } from "react";
import {
  Grid,
  TextField,
  Button,
  Switch,
  FormControlLabel,
} from "@mui/material";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useParams } from "react-router-dom";
import { getShopById } from "../../../API/ShopApi";
import { createOffer } from "../../../API/OfferApi";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { setLoading } from "../../../store/actions/actions";
import QuillEditor from "../../Elements/RichEditor/QuillEditor";

const AddOffers = () => {
  const { shopId } = useParams();
  const [shop, setShop] = useState({});
  const [isPriority, setIsPriority] = useState(false); // State for toggle
  const [isVerified, setIsVerified] = useState(false); // State for toggle
  const dispatch = useDispatch();
  const {
    handleSubmit,
    register,
    setValue,
    watch,
    formState: { errors },
  } = useForm();
  const [img, setImg] = useState({ preview: "", raw: null });
  const [termsConditions, setTermsConditions] = useState({
    termsConditions: "",
  });
  const offerStart = watch("offerStart");
  const offerEnd = watch("offerEnd");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getShopById(shopId);
        setShop(response.data);
        setValue("shopName", response.data.name);
      } catch (error) {
        console.error("Error fetching shop data:", error);
      }
    };

    fetchData();
  }, [shopId, setValue]);

  const handleLoading = (value) => {
    dispatch(setLoading(value));
  };

  const onImageChange = (e) => {
    setImg({
      preview: URL.createObjectURL(e.target.files[0]),
      raw: e.target.files[0],
    });
  };

  const handleDateChange = (date, field) => {
    setValue(field, date ? date.getTime() : null);
    if (field === "offerStart" && date) {
      setValue(
        "offerEnd",
        new Date(date.getTime() + 24 * 60 * 60 * 1000).getTime()
      );
    }
  };

  const onSubmit = async (data) => {
    handleLoading(true);

    const formData = new FormData();
    formData.append("name", data.name);
    formData.append("discount", data.discount);
    formData.append("fixDiscount", data.fixDiscount);
    formData.append("shopId", shopId);
    formData.append("termsConditions", termsConditions.termsConditions);
    formData.append("offerStart", data.offerStart);
    formData.append("offerEnd", data.offerEnd);
    formData.append("shopName", shop.name);
    formData.append("img", img.raw);
    formData.append("isVerified", isVerified);
    formData.append("isPriority", isPriority);

    try {
      const response = await createOffer(formData);
      handleLoading(false);
      if (response.status) {
        toast.success(response.message);
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      handleLoading(false);
      toast.error("Error submitting form: " + error.message);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} style={formStyle}>
      <h1 style={{ textAlign: "center", marginBottom: "40px" }}>
        Create an Offer
      </h1>

      {/* Toggle for column layout */}
      <div
        style={{
          marginBottom: "20px",
          display: "flex",
          justifyContent: "center",
        }}></div>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <TextField
            fullWidth
            name="name"
            label="Offer Name"
            onChange={(e) => setValue("name", e.target.value)}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            name="discount"
            type="text"
            label="Discount"
            {...register("discount", { type: "text" })}
            error={!!errors.discount}
            helperText={errors.discount?.message}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            name="fixDiscount"
            type="number"
            label="Discount in Number"
            {...register("fixDiscount", {
              type: "number",
            })}
            error={!!errors.fixDiscount}
            helperText={errors.fixDiscount?.message}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            name="shopId"
            label="Shop ID"
            value={shopId}
            disabled
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            name="shopName"
            label="Shop Name"
            value={shop.name || ""}
            disabled
          />
        </Grid>
        <Grid item xs={6}>
          <FormControlLabel
            control={
              <Switch
                checked={isVerified}
                onChange={() => setIsVerified(!isVerified)}
              />
            }
            label="Is this a Verified Offer?"
          />
        </Grid>
        <Grid item xs={6}>
          <FormControlLabel
            control={
              <Switch
                checked={isPriority}
                onChange={() => setIsPriority(!isPriority)}
              />
            }
            label="Is this a Priority Offer?"
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <DatePicker
            selected={offerStart ? new Date(offerStart) : null}
            onChange={(date) => handleDateChange(date, "offerStart")}
            showTimeSelect
            dateFormat="Pp"
            minDate={new Date()}
            customInput={<TextField fullWidth label="Offer Start" />}
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <DatePicker
            selected={offerEnd ? new Date(offerEnd) : null}
            onChange={(date) => handleDateChange(date, "offerEnd")}
            showTimeSelect
            dateFormat="Pp"
            minDate={
              offerStart
                ? new Date(offerStart + 24 * 60 * 60 * 1000)
                : new Date()
            }
            customInput={<TextField fullWidth label="Offer End" />}
          />
        </Grid>

        <Grid item xs={12}>
          <QuillEditor
            value={termsConditions}
            onStateChange={setTermsConditions}
            heading="About the Offer"
            title="About the Offer"
            htmlKey="termsConditions"
          />
        </Grid>

        <Grid item xs={12}>
          <div style={{ fontSize: "18px", fontWeight: "bold" }}>Add Image</div>
          <input type="file" accept="image/*" onChange={onImageChange} />
          {img.preview && (
            <img
              src={img.preview}
              alt="Preview"
              style={{ maxWidth: "200px", marginTop: "10px" }}
            />
          )}
        </Grid>

        <Grid item xs={12}>
          <Button type="submit" variant="contained" color="primary">
            Create Offer
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

const formStyle = {
  margin: "20px auto",
  maxWidth: "800px",
  padding: "20px",
  border: "1px solid #ddd",
  borderRadius: "8px",
};

export default AddOffers;
