import React, { useState } from "react";
import {
  TableRow,
  TableCell,
  Button,
  Modal,
  Box,
  Typography,
  IconButton,
} from "@mui/material";
import axios from "axios";
import "boxicons"; // Ensure boxicons are installed and imported
import { toast } from "react-toastify";
import CheckIcon from "@mui/icons-material/Check";
import { Link } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";

const OfferRow = ({ imgUrl, offerText, offerCode, offerId, shopId }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [open, setOpen] = useState(false); // State for modal visibility

  // Handle modal open/close
  const handleOpen = (e) => {
    e.stopPropagation();
    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  // Handle offer deletion
  const handleDelete = async () => {
    try {
      await axios.delete(`https://server.atf-labs.com:3000/offers/${offerId}`);
      toast.success("Offer deleted successfully!");
      handleClose();
      setTimeout(() => {
        window.location.reload();
      }, 2000); // Delay reload by 2 seconds
    } catch (error) {
      toast.error("Error deleting offer: " + error.message);
    }
  };

  // Handle offer verification
  const handleVerify = async (e) => {
    e.stopPropagation();
    try {
      const formData = new FormData();
      formData.append("isVerified", true);

      const response = await axios.patch(
        `https://server.atf-labs.com:3000/offers/${offerId}/verify`,
        formData
      );

      if (response.data.status === true) {
        toast.success("Offer verified successfully!");
        setTimeout(() => {
          window.location.reload();
        }, 2000); // Delay reload by 2 seconds
      } else {
        toast.error("Failed to verify offer.");
      }
    } catch (error) {
      toast.error("Error verifying offer: " + error.message);
    }
  };

  // Handle offer ID copy
  const handleCopyClick = () => {
    navigator.clipboard.writeText(offerCode);
    toast.success("Offer Id code copied!");
  };

  // Handle row click
  //   const handleRowClick = () => {
  //     window.location.href = `${linkUrl + "/" + offerCode}`;
  //   };

  return (
    <>
      <TableRow
        hover
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        // onClick={handleRowClick}
        sx={{ cursor: "pointer" }}>
        {/* Offer Image */}
        <TableCell>
          <img src={imgUrl || "/no-image.jpg"} alt="Offer" width={60} />
        </TableCell>

        {/* Offer Text */}
        <TableCell>{offerText}</TableCell>

        {/* Offer ID */}
        <TableCell onClick={(e) => e.stopPropagation()}>
          <span
            style={{ cursor: "pointer", color: "blue" }}
            onClick={handleCopyClick}>
            {offerCode}
          </span>
        </TableCell>

        {/* Action Buttons */}
        <TableCell>
          <Link
            to={`/admin/verifiedShopsList/editOffers/${shopId}/${offerId}`}
            style={{ textDecoration: "none", color: "inherit" }}>
            <IconButton title="View">
              <VisibilityIcon sx={{ color: "blue" }} />
            </IconButton>
          </Link>

          {/* Verify Button */}
          <IconButton
            onClick={handleVerify}
            color="success"
            title="Verify Offer">
            <CheckIcon />
          </IconButton>

          {/* Delete Button */}
          <IconButton onClick={handleOpen} color="error" title="Delete Offer">
            <box-icon
              name="x"
              type="regular"
              color="red"
              title="Delete Offer"
            />
          </IconButton>
        </TableCell>
      </TableRow>

      {/* Modal for Delete Confirmation */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="delete-confirmation-title"
        aria-describedby="delete-confirmation-description">
        <Box sx={modalStyle}>
          <Typography
            id="delete-confirmation-title"
            variant="h6"
            component="h2">
            Confirm Delete
          </Typography>
          <Typography id="delete-confirmation-description" sx={{ mt: 2 }}>
            Are you sure you want to delete this offer? This action cannot be
            undone.
          </Typography>
          <Box sx={{ mt: 3, display: "flex", justifyContent: "flex-end" }}>
            <Button onClick={handleClose} variant="outlined" sx={{ mr: 2 }}>
              Cancel
            </Button>
            <Button onClick={handleDelete} variant="contained" color="error">
              Delete
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

// Modal styling
const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "8px",
};

export default OfferRow;
