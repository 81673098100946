// CommonImageApi.js
import axios from "axios";
import { backendApiUrl } from "../constants";

const baseURL = backendApiUrl;

const CommonImageApi = axios.create({
  baseURL,
  headers: {
    "Content-Type": "application/json",
  },
});

// Add an interceptor to include the token in the Authorization header for all requests
CommonImageApi.interceptors.request.use(
  (config) => {
    const authToken = localStorage.getItem("token"); // Assuming you store the token in localStorage
    if (authToken) {
      config.headers.Authorization = `Bearer ${authToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Define your common image-related API endpoints
export const getImage = async () => {
  try {
    const response = await CommonImageApi.get(`/common/image`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const uploadImage = async (imageData) => {
  try {
    const response = await CommonImageApi.put(`/common/image`, imageData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export default CommonImageApi;
