import React from "react";
import {
  TableCell,
  TableRow,
  Button,
  IconButton,
  Typography,
  Box,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import { Link } from "react-router-dom";
import Carousel from "../carousel/Caraousal";
import DOMPurify from "dompurify";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { setLoading } from "../../../store/actions/actions";
import { deleteProduct, verifyProduct } from "../../../API/ProductApi";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
const UnVerifiedProductRow = (props) => {
  const { imgUrl, Product_Name, Product_Desc, Price, id, shopId } = props;
  const dispatch = useDispatch();

  const handleLoading = (value) => {
    dispatch(setLoading(value));
  };

  const verified = () => {
    handleLoading(true);
    const formData = new FormData();
    formData.append("isVerified", true);

    verifyProduct(props.id, formData)
      .then((response) => {
        if (response.status === true) {
          handleLoading(false);
          toast.success(response.message);
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        } else {
          handleLoading(false);
          toast.error(response.message);
        }
      })
      .catch((error) => {
        console.error("Error verifying product:", error);
      });
  };

  const erase = async () => {
    handleLoading(true);
    deleteProduct(id)
      .then((response) => {
        if (response.status === false) {
          handleLoading(false);
          toast.error(response.message);
        } else {
          handleLoading(false);
          toast.success(response.message);
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        }
      })
      .catch((error) => {
        console.error("Error deleting product:", error);
      });
  };

  const truncateDescription = (description, limit) =>
    description.length > limit
      ? `${description.substring(0, limit)}...`
      : description;

  const createMarkup = (html) => ({ __html: DOMPurify.sanitize(html) });

  return (
    <TableRow>
      <TableCell>
        <Box
          height="80px"
          display="flex"
          justifyContent="left"
          alignItems="center">
          {typeof imgUrl !== "string" && imgUrl.length > 0 ? (
            <Carousel images={imgUrl} shopId={shopId} id={id} />
          ) : (
            <Link
              to={`/admin/verifiedShopsList/showProducts/${shopId}/${id}`}
              style={{ textDecoration: "none" }}>
              <img
                src={imgUrl || "/no-image.jpg"}
                alt="Product"
                width="80"
                height="80"
              />
            </Link>
          )}
        </Box>
      </TableCell>
      <TableCell>
        <Typography fontWeight="700" fontSize="16px">
          {truncateDescription(Product_Name, 20)}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography>
          <div
            dangerouslySetInnerHTML={createMarkup(
              truncateDescription(Product_Desc, 60)
            )}
          />
        </Typography>
      </TableCell>
      <TableCell>
        <Typography color="text.secondary" fontSize="14px">
          <b>Price:</b> {Price}
        </Typography>
      </TableCell>
      <TableCell>
        <Link
          to={`/admin/verifiedShopsList/showProducts/${shopId}/${id}`}
          style={{ textDecoration: "none", color: "inherit" }}>
          <IconButton title="View">
            <VisibilityIcon sx={{ color: "blue" }} />
          </IconButton>
        </Link>
        <IconButton
          title="Verify"
          variant="contained"
          color="success"
          startIcon={<CheckIcon />}
          onClick={verified}>
          <VerifiedUserIcon />
        </IconButton>
        <IconButton title="Delete" onClick={erase} color="error">
          <CloseIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

export default UnVerifiedProductRow;
