// OfferApi.js
import axios from "axios";
import { backendApiUrl } from "../constants";

const baseURL = backendApiUrl;

const OfferApi = axios.create({
  baseURL,
  headers: {
    "Content-Type": "application/json",
  },
});

// Add an interceptor to include the token in the Authorization header for all requests
OfferApi.interceptors.request.use(
  (config) => {
    const authToken = localStorage.getItem("token"); // Assuming you store the token in localStorage
    if (authToken) {
      config.headers.Authorization = `Bearer ${authToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Define your product offer-related API endpoints
export const getOffers = async () => {
  try {
    const response = await OfferApi.get("/offers");
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getUnverifiedOffers = async () => {
  try {
    const response = await OfferApi.get("/all/offers");
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getOfferById = async (id) => {
  try {
    const response = await OfferApi.get(`/offers/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getOfferByShopId = async (id) => {
  try {
    const response = await OfferApi.get(`/offers/${id}/shop`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const createOffer = async (offerData) => {
  try {
    const response = await fetch("https://server.atf-labs.com:3000/offers", {
      method: "POST",
      body: offerData, // formData being passed
    });

    // Convert the response to JSON (this was missing)
    const result = await response.json();
    return result; // Return the parsed JSON result
  } catch (error) {
    throw error; // Throw the error so it can be caught by the caller
  }
};

export const updateOffer = async (id, offerData) => {
  try {
    const response = await fetch(
      `https://server.atf-labs.com:3000/offers/${id}`,
      {
        method: "PUT", // Use PUT for updating the offer
        body: offerData, // formData being passed (like in createOffer)
      }
    );

    // Log formData for debugging (optional)
    for (const value of offerData.values()) {
      console.log("FormData:", value);
    }

    // Convert the response to JSON
    const result = await response.json();
    return result; // Return the parsed JSON result
  } catch (error) {
    throw error; // Throw the error so it can be caught by the caller
  }
};

export const deleteOffer = async (id) => {
  try {
    const response = await OfferApi.delete(`/offers/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Add other product offer-related API endpoints as needed

export default OfferApi;
