// reducers/authSlice.js

import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
  name: "auth",
  initialState: {
    isAuthenticated: false,
  },
  reducers: {
    setAuthToken(state, action) {
      state.isAuthenticated = action.payload;
    },
    clearAuthToken(state) {
      state.isAuthenticated = false;
    },
  },
});

export const { setAuthToken, clearAuthToken } = authSlice.actions;
export default authSlice.reducer;
