// import React, { useEffect } from "react";
// import { useLocation } from "react-router-dom";
// import { useSelector } from "react-redux";

// // Global object to store scroll positions for different paths
// const scrollPositions = {};

// // Save the current scroll position
// const saveScrollPosition = (key) => {
//   const position = window.scrollY || 0;
//   scrollPositions[key] = position;
//   console.log(`[SAVE] Scroll position for ${key}:`, position);
// };

// // Restore a saved scroll position or scroll to the top for new pages
// const restoreScrollPosition = (key) => {
//   if (scrollPositions[key] !== undefined) {
//     console.log(
//       `[RESTORE] Restoring scroll position for ${key}:`,
//       scrollPositions[key]
//     );
//     window.scrollTo(0, scrollPositions[key]);
//   } else {
//     console.log(`[RESTORE] No saved position for ${key}, scrolling to top.`);
//     window.scrollTo(0, 0);
//   }
// };

// // Function to handle restoration after a delay, useful to ensure page is fully loaded before restoring position
// const restoreWithDelay = (key, delay) => {
//   setTimeout(() => {
//     if (document.readyState === "complete") {
//       restoreScrollPosition(key);
//     } else {
//       const handleLoad = () => {
//         restoreScrollPosition(key);
//         window.removeEventListener("load", handleLoad); // Clean up after restoring
//       };
//       window.addEventListener("load", handleLoad); // Attach event to restore scroll after load
//     }
//   }, delay);
// };

// const ScrollRetention = ({ children }) => {
//   const location = useLocation();
//   const isLoading = useSelector((state) => state.isLoading); // Access the `isLoading` state from Redux to handle page load state

//   useEffect(() => {
//     // Disable browser's default scroll restoration behavior
//     if ("scrollRestoration" in window.history) {
//       window.history.scrollRestoration = "manual"; // Set this to "manual" to prevent browser default scroll behavior
//     }

//     const { pathname } = location;

//     // New pages or routes without saved positions, scroll to top by default
//     if (scrollPositions[pathname] === undefined) {
//       console.log(`[NEW PAGE] Scrolling to top for ${pathname}`);
//       window.scrollTo(0, 0); // Scroll to top for new paths
//     } else {
//       // If scroll position is found and it's not loading, restore it
//       if (!isLoading) {
//         restoreWithDelay(pathname, 1000);
//       }
//     }

//     // Save the scroll position whenever the route changes or component unmounts
//     return () => {
//       // Only save scroll position if not in a loading state, to avoid saving incorrect scroll data during async content loading
//       if (!isLoading) {
//         saveScrollPosition(pathname);
//       }
//     };
//   }, [location.pathname, isLoading]); // Trigger the effect when pathname or isLoading changes

//   useEffect(() => {
//     // Save scroll position while the user scrolls
//     const handleScroll = () => {
//       if (!isLoading) {
//         saveScrollPosition(location.pathname); // Save scroll position only if not loading
//       }
//     };

//     // Add scroll event listener to capture scrolling behavior
//     window.addEventListener("scroll", handleScroll);

//     return () => {
//       // Clean up the scroll event listener on component unmount
//       window.removeEventListener("scroll", handleScroll);
//     };
//   }, [location.pathname, isLoading]); // Re-create the scroll listener whenever pathname or isLoading changes

//   return <div>{children}</div>; // Render the children components
// };

// export default ScrollRetention;

import React, { useLayoutEffect, useEffect } from "react";
import { useLocation } from "react-router-dom";

// Object to hold scroll positions in memory
const scrollPositions = {};
// Timestamps to manage expiry (5 minutes)
const scrollTimestamps = {};

// Helper to save scroll position
const saveScrollPosition = (key) => {
  scrollPositions[key] = window.scrollY;
  scrollTimestamps[key] = Date.now();
  console.log(`[SAVE] Scroll position for ${key}:`, window.scrollY);
};

// Helper to restore scroll position
const restoreScrollPosition = (key) => {
  const isExpired = Date.now() - (scrollTimestamps[key] || 0) > 5 * 60 * 1000; // Expiry of 5 minutes

  if (!isExpired && scrollPositions[key] !== undefined) {
    console.log(`[RESTORE] Scroll position for ${key}:`, scrollPositions[key]);
    window.scrollTo(0, scrollPositions[key]);
  } else {
    console.log(
      `[RESTORE] No valid scroll position for ${key}, defaulting to 0.`
    );
    window.scrollTo(0, 0);
  }
};

// Wait until the DOM content (scrollable area) is fully available
const waitForScrollElement = (callback) => {
  const observer = new MutationObserver((mutationsList) => {
    for (let mutation of mutationsList) {
      if (
        mutation.type === "childList" &&
        document.documentElement.scrollHeight > 0
      ) {
        callback();
        observer.disconnect(); // Stop observing once the DOM is ready
        break;
      }
    }
  });

  observer.observe(document.body, { childList: true, subtree: true });
};

const useScrollRestoration = () => {
  const location = useLocation();
  const { pathname, search } = location;
  const fullPath = `${pathname}${search}`; // Full key for each route

  // On layout effect, restore scroll position or scroll to top for new links
  useLayoutEffect(() => {
    waitForScrollElement(() => restoreScrollPosition(fullPath));

    // Save position when navigating away
    return () => saveScrollPosition(fullPath);
  }, [fullPath]); // Dependency ensures effect runs on route change

  // Save scroll position while scrolling
  useEffect(() => {
    const handleScroll = () => saveScrollPosition(fullPath);

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [fullPath]);
};

const ScrollRetention = ({ children }) => {
  useScrollRestoration();

  return <div>{children}</div>;
};

export default ScrollRetention;
