/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import {
  Box,
  Button,
  Typography,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Paper,
} from "@mui/material";
import empty from "../Elements/Images/empty1.gif";
import ProductItem from "../Views/ListItem/ProductItem"; // Replace ProductCard with ProductItem
import { getProductByShopId } from "../../API/ProductApi";

export default function ProductList() {
  const [products, setProducts] = useState([]);
  const { shopId } = useParams();

  const fetchProducts = () => {
    getProductByShopId(shopId)
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        // Filtering and sorting data by updatedAt (latest first)
        const filteredAndSortedData = data
          .filter((d) => d.isVerified === true)
          .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));

        console.log(filteredAndSortedData);
        setProducts(filteredAndSortedData);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  return (
    <>
      <Box>
        <Typography
          variant="h3"
          align="center"
          fontWeight="600"
          marginTop="5vh">
          Shop Products
        </Typography>
      </Box>
      {products.length > 0 ? (
        <Box>
          <Box align="right" margin="20px">
            <Link to={`/admin/verifiedShopsList/addProduct/${shopId}`}>
              <Button variant="contained">Add Product</Button>
            </Link>
          </Box>
          <TableContainer
            component={Paper}
            sx={{
              width: "86%",
              margin: "20px auto",
              marginLeft: "9%",
              boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
              borderRadius: "8px",
            }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <b>Image</b>
                  </TableCell>
                  <TableCell>
                    <b>Product Name</b>
                  </TableCell>
                  <TableCell>
                    <b>Description</b>
                  </TableCell>
                  <TableCell>
                    <b>Price</b>
                  </TableCell>
                  <TableCell>
                    <b>Actions</b>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {products.map((product) => (
                  <ProductItem
                    key={product._id}
                    Product_Name={product.name}
                    Product_Desc={product.desc}
                    Price={product.price}
                    imgUrl={product.thumbnailURL}
                    isPriority={product.isPriority}
                    id={product._id}
                    mrp={product.mrp}
                    shopId={shopId}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      ) : (
        <Box
          display="inlineFlex"
          alignItems="top"
          height="100vh"
          justifyContent="center">
          <Box>
            <Box display="flex" justifyContent="center">
              <img src={empty} width="500px" alt="No Products" />
            </Box>
            <Box align="center">
              <Link to={`/admin/verifiedShopsList/addProduct/${shopId}`}>
                <Button variant="contained">Add Some</Button>
              </Link>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
}
