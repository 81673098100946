/* eslint-disable no-unused-expressions */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  BottomNavigation,
  BottomNavigationAction,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import DiscountIcon from "@mui/icons-material/Discount";
import StoreIcon from "@mui/icons-material/Store";
import InventoryIcon from "@mui/icons-material/Inventory";
import ShopIcon from "@mui/icons-material/Shop";
import empty from "../Elements/Images/empty1.gif";
import { getUnverifiedProducts } from "../../API/ProductApi";
import { getUnverifiedOffers } from "../../API/OfferApi";
import { getUnferiviedShops } from "../../API/ShopApi";
import UnVerifiedProductRow from "./ListItem/UnVerifiedProductRow";
import UnVerifiedOfferRow from "./ListItem/UnVerifiedOfferRow";
import ProductItem from "./ListItem/ProductItem";
import UnVerifiedShopRow from "./ListItem/UnVerifiedShopRow";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { setValue } from "../../store/actions/actions";
import { setLoading } from "../../store/actions/actions";
import Sort from "../Elements/Sort/Sort";

export default function NewRequestList() {
  const [products, setProducts] = useState([]);
  const [offers, setOffers] = useState([]);
  const [shops, setShops] = useState([]);
  const [verifiedProducts, setVerifiedProducts] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const dispatch = useDispatch();
  const value = useSelector((state) => state.value.value);
  const handleLoading = (value) => {
    dispatch(setLoading(value));
  };
  const handleChange = (event, value) => {
    dispatch(setValue(value || "Offers"));
    setFilteredData([]);
  };

  const shopId = useParams();

  const fetchData = () => {
    getUnverifiedProducts()
      .then((response) => response.data)
      .then((data) => {
        // Filter and sort products
        if (data.status == true) {
          handleLoading(false);
          return;
        }
        const filteredAndSortedProducts = data
          .filter((d) => d.isVerified === false)
          .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
        const verifiedAndSortedProducts = data
          .filter((d) => d.isVerified === true)
          .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
        setProducts(filteredAndSortedProducts);
        setVerifiedProducts(verifiedAndSortedProducts);
      })
      .catch((error) => console.error("Error fetching products:", error));

    getUnverifiedOffers()
      .then((response) => response.data)
      .then((data) => {
        // Filter and sort offers
        const filteredAndSortedOffers = data
          .filter((d) => d.isVerified === false)
          .sort((a, b) => new Date(b.updatedOn) - new Date(a.updatedOn));
        setOffers(filteredAndSortedOffers);
      })
      .catch((error) => console.error("Error fetching offers:", error));

    getUnferiviedShops()
      .then((response) => response.data)
      .then((data) => {
        // Filter and sort shops
        const filteredAndSortedShops = data
          .filter((d) => d.isVerified === false)
          .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
        setShops(filteredAndSortedShops);
      })
      .catch((error) => console.error("Error fetching shops:", error));
  };

  useEffect(() => {
    fetchData();
  }, []);

  // Log state changes for debugging
  useEffect(() => {
    console.log("Products updated:", products);
  }, [products]);

  useEffect(() => {
    console.log("Offers updated:", offers);
  }, [offers]);

  useEffect(() => {
    console.log("Shops updated:", shops);
  }, [shops]);

  return (
    <>
      <Box width="100%" align="center" marginTop="5vh">
        <BottomNavigation
          sx={{ width: 500 }}
          value={value}
          onChange={(e, newValue) => handleChange(e, newValue)}>
          <BottomNavigationAction
            label="Unverified Offers"
            value="Offers"
            icon={<DiscountIcon />}
          />
          <BottomNavigationAction
            label="Verified Products"
            value="VProducts"
            icon={<InventoryIcon />}
          />
          <BottomNavigationAction
            label="Unverified Products"
            value="Products"
            icon={<ShopIcon />}
          />
          <BottomNavigationAction
            label="Unverified Shops"
            value="Shops"
            icon={<StoreIcon />}
          />
        </BottomNavigation>
      </Box>
      <Box>
        {value === "Products" ? (
          <>
            <TableContainer
              component={Paper}
              sx={{
                width: "86%",
                margin: "20px auto",
                marginLeft: "9%",
                boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                borderRadius: "8px",
              }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <b>Image</b>
                    </TableCell>
                    <TableCell>
                      <b>Product Name</b>
                    </TableCell>
                    <TableCell>
                      <b>Description</b>
                    </TableCell>
                    <TableCell>
                      <b>Price</b>
                    </TableCell>
                    <TableCell>
                      <b>Actions</b>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(filteredData.length > 0 ? filteredData : products).map(
                    (product) => (
                      <UnVerifiedProductRow
                        key={product._id}
                        imgUrl={product.thumbnailURL}
                        Product_Name={product.name}
                        Product_Desc={product.desc}
                        Price={product.price}
                        id={product._id}
                        shopId={shopId}
                      />
                    )
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        ) : value === "VProducts" ? (
          <>
            <TableContainer
              component={Paper}
              sx={{
                width: "86%",
                margin: "20px auto",
                marginLeft: "9%",
                boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                borderRadius: "8px",
              }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <b>Image</b>
                    </TableCell>
                    <TableCell>
                      <b>Product Name</b>
                    </TableCell>
                    <TableCell>
                      <b>Description</b>
                    </TableCell>
                    <TableCell>
                      <b>Price</b>
                    </TableCell>
                    <TableCell>
                      <b>Actions</b>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Sort
                        data={verifiedProducts}
                        onFilter={setFilteredData}
                      />
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {(filteredData.length > 0
                    ? filteredData
                    : verifiedProducts
                  ).map((product) => (
                    <ProductItem
                      key={product._id}
                      imgUrl={product.thumbnailURL}
                      Product_Name={product.name}
                      Product_Desc={product.desc}
                      Price={product.price}
                      id={product._id}
                      shopId={shopId}
                    />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        ) : value === "Offers" ? (
          <>
            <TableContainer
              component={Paper}
              sx={{
                width: "86%",
                margin: "20px auto",
                marginLeft: "9%",
                boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                borderRadius: "8px",
              }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <b>Image</b>
                    </TableCell>
                    <TableCell>
                      <b>Offer</b>
                    </TableCell>
                    <TableCell>
                      <b>Offer Code</b>
                    </TableCell>
                    <TableCell>
                      <b>Actions</b>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(filteredData.length > 0 ? filteredData : offers).map(
                    (offer) => (
                      <UnVerifiedOfferRow
                        offerCode={offer.offerCode}
                        imgUrl={offer.imgURL}
                        offerText={offer.discount}
                        offerId={offer._id}
                        offerLink={offer.linkUrl}
                        shopId={offer.shopId}
                      />
                    )
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        ) : value === "Shops" ? (
          <>
            <TableContainer
              component={Paper}
              sx={{
                width: "86%",
                margin: "20px auto",
                marginLeft: "9%",
                boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                borderRadius: "8px",
              }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <b>Shop Image</b>
                    </TableCell>
                    <TableCell>
                      <b>Shop Name</b>
                    </TableCell>
                    <TableCell>
                      <b>Karta</b>
                    </TableCell>
                    <TableCell>
                      <b>Contact Number</b>
                    </TableCell>
                    <TableCell>
                      <b>Actions</b>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(filteredData.length > 0 ? filteredData : shops).map(
                    (shop) => (
                      <UnVerifiedShopRow
                        key={shop._id}
                        imgUrl={shop.shopImg}
                        Shop_Name={shop.name}
                        Karta_Name={shop.kartaName}
                        Mobile={shop.mobile}
                        id={shop._id}
                      />
                    )
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        ) : (
          <Box
            display="flex"
            alignItems="center"
            height="100vh"
            justifyContent="center">
            <img src={empty} width="500px" alt="No Requests" />
          </Box>
        )}
      </Box>
    </>
  );
}
