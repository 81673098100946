import React, { useState, useEffect } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const QuillEditor = ({ value, onStateChange, heading, htmlKey }) => {
  const [editorHtml, setEditorHtml] = useState(""); // State for HTML content

  // Initialize the editor with HTML content from the parent (if provided)
  useEffect(() => {
    if (value && value[htmlKey]) {
      setEditorHtml(value[htmlKey]); // Prepopulate HTML content
    }
  }, [value, htmlKey]);

  const handleEditorChange = (content) => {
    setEditorHtml(content); // Update the editor state
    const updatedValue = {
      ...value,
      [htmlKey]: content, // Save the HTML back to parent
    };

    onStateChange(updatedValue);

    console.log(value);
  };

  return (
    <div className="Editor">
      <h3 style={{ textAlign: "left", margin: 0 }}>{heading}</h3>
      <ReactQuill
        value={editorHtml}
        onChange={handleEditorChange}
        modules={{
          toolbar: [
            [{ header: "1" }, { header: "2" }, { font: [] }],
            [{ list: "ordered" }, { list: "bullet" }],
            ["bold", "italic", "underline", "strike"], // toggled buttons
            [{ color: [] }, { background: [] }], // dropdown with defaults from theme
            [{ align: [] }],
            ["link", "image"], // Add link and image options
            ["clean"], // remove formatting button
          ],
        }}
        formats={[
          "header",
          "font",
          "list",
          "bullet",
          "bold",
          "italic",
          "underline",
          "strike",
          "link",
          "color",
          "background",
          "align",
          "image",
        ]}
        theme="snow" // Set the theme
      />
    </div>
  );
};

export default QuillEditor;
