import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom"; // Import useLocation to access query parameters
import { sendNotificationAdmin } from "../../../API/CustomNotificationApi";
import { toast } from "react-toastify";
import "./CustomNotification.css";

const CustomNotification = () => {
  const location = useLocation(); // Get the location object
  const [pageType, setPageType] = useState("shop");
  const [formData, setFormData] = useState({
    title: "",
    body: "",
    notificationImg: null, // Store the file instead of the URL
    data: { shop: "" }, // Default data for 'shop'
  });
  const [imagePreview, setImagePreview] = useState(null); // Store the preview URL

  // Extract query parameters and set initial values
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get("id");
    const notificationType = queryParams.get("type");

    // Set pageType based on the notificationType
    if (
      notificationType === "shop" ||
      notificationType === "product" ||
      notificationType === "offer"
    ) {
      setPageType(notificationType);
      setFormData((prevData) => ({
        ...prevData,
        data: { [notificationType]: id || "" }, // Prepopulate with id if exists
      }));
    }
  }, [location.search]);

  // Handle page type switch
  const handlePageTypeChange = (newPageType) => {
    setPageType(newPageType);
    setFormData({
      title: "",
      body: "",
      notificationImg: null,
      data: { [newPageType]: "" },
    });
    setImagePreview(null); // Reset image preview on page type change
  };

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleDataChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      data: {
        ...prevData.data,
        [name]: value,
      },
    }));
  };

  // Handle image selection
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setFormData((prevData) => ({
      ...prevData,
      notificationImg: file, // Save the file in state
    }));
    setImagePreview(file ? URL.createObjectURL(file) : null); // Generate preview URL
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Create FormData
    const payload = new FormData();
    payload.append("title", formData.title);
    payload.append("body", formData.body);
    if (formData.notificationImg) {
      payload.append("notificationImg", formData.notificationImg); // Add file
    }
    if (pageType === "product") {
      payload.append("data[product]", formData.data[pageType]);
    } else if (pageType === "offer") {
      payload.append("data[offer]", formData.data[pageType]);
    } else {
      payload.append("data[shop]", formData.data[pageType]);
    }

    try {
      const response = await sendNotificationAdmin(payload); // Send FormData
      console.log("Notification sent successfully:", response);
    } catch (error) {
      console.error("Error sending notification:", error);
      toast.error("Failed to send notification.");
    }
  };

  return (
    <div className="container">
      <div className="form-container">
        <h2>Dynamic Notification Form</h2>

        <div className="button-group">
          <button
            className={`page-btn ${pageType === "shop" ? "active" : ""}`}
            onClick={() => handlePageTypeChange("shop")}>
            Shop
          </button>
          <button
            className={`page-btn ${pageType === "product" ? "active" : ""}`}
            onClick={() => handlePageTypeChange("product")}>
            Product
          </button>
          <button
            className={`page-btn ${pageType === "offer" ? "active" : ""}`}
            onClick={() => handlePageTypeChange("offer")}>
            Offer
          </button>
        </div>

        <div className={`form-slide ${pageType}`}>
          <form onSubmit={handleSubmit} className="form">
            <div className="form-field">
              <label>Title</label>
              <input
                type="text"
                name="title"
                value={formData.title}
                onChange={handleInputChange}
              />
            </div>

            <div className="form-field">
              <label>Body</label>
              <textarea
                name="body"
                value={formData.body}
                onChange={handleInputChange}
              />
            </div>

            <div className="form-field">
              <label>Notification Image</label>
              <input
                type="file"
                accept="image/*"
                onChange={handleImageChange}
              />
              {imagePreview && (
                <div className="image-preview">
                  <img src={imagePreview} alt="Preview" width="300px" />
                </div>
              )}
            </div>

            <div className="form-field">
              <label>
                {pageType === "product"
                  ? "Product ID"
                  : pageType === "offer"
                  ? "Offer ID"
                  : "Shop ID"}
              </label>
              <input
                type="text"
                name={pageType}
                value={formData.data[pageType]}
                onChange={handleDataChange}
              />
            </div>

            <button type="submit">Submit</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CustomNotification;
