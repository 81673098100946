/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState, useEffect } from "react";
import { getImage, uploadImage } from "../../API/CommonImageApi"; // Adjust the import path as needed
import { setLoading } from "../../store/actions/actions";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { Button, TextField, Typography } from "@mui/material";

const GlobalAsset = () => {
  const [images, setImages] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState(null);
  const dispatch = useDispatch();
  const [img, setImg] = useState({ preview: "", raw: "" });
  const [cashbackInfo, setCashbackInfo] = useState({ cashbackInfo: "" });

  const handleLoading = (value) => {
    dispatch(setLoading(value));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log("Name:", name, "Value:", value);
    setCashbackInfo((prevCashbackInfo) => ({
      ...prevCashbackInfo,
      [name]: value,
    }));
  };

  useEffect(() => {
    // Fetch all images on component mount
    const fetchImages = async () => {
      try {
        const imageResponses = await getImage()
          .then((images) => {
            return images;
          })
          .then((filteredImages) => {
            if (filteredImages.status === true) {
              setImages([filteredImages.data]);
              console.log("Images:", images);
              setCashbackInfo({
                cashbackInfo: filteredImages.data.cashBackInfo,
              });
              toast.success(filteredImages.message);
            }
          })
          .catch((err) => {
            toast.error(err.message);
          });
      } catch (err) {
        setError("Failed to fetch images.");
      }
    };

    fetchImages();
  }, []);

  const handleFileChange = (e) => {
    if (e.target.files.length > 0) {
      const file = e.target.files[0];
      const cleanedFile = new File([file], file.name.replace(/\s+/g, ""), {
        type: file.type,
      });

      setImg({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0],
      });
    }
  };

  const handleUpload = async () => {
    if (!img.raw) {
      console.error("No file selected");
      return;
    }
    handleLoading(true);

    const formData = new FormData();
    formData.append("globalasset", img.raw);
    formData.append("cashBackInfo", cashbackInfo.cashbackInfo);

    try {
      const response = await fetch(
        "https://server.atf-labs.com:3000/common/image",
        {
          method: "PUT",
          body: formData,
        }
      );

      const data = await response.json();
      if (data.status === true) {
        handleLoading(false);
        toast.success(data.message);
        setImg({ preview: "", raw: "" });
        setTimeout(() => {
          window.location.reload();
        }, 5000);
      } else {
        handleLoading(false);
        toast.error(data.message);
      }
    } catch (err) {
      handleLoading(false);
      toast.error(err.message);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100vh",
      }}>
      <h1>Image Upload</h1>

      {error && <p style={{ color: "red" }}>{error}</p>}
      {img && <img src={img.preview} alt="Selected preview" width="50px" />}

      <div
        style={{
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
        }}>
        <input type="file" onChange={handleFileChange} />
        <TextField
          id="outlined-basic"
          label="Update Cashback Info"
          variant="outlined"
          type="number"
          name="cashbackInfo"
          value={cashbackInfo.cashbackInfo}
          style={{ margin: "10px" }}
          onChange={handleChange}
        />
        <Button onClick={handleUpload} variant="contained" color="primary">
          {uploading ? "Uploading..." : "Upload"}
        </Button>
      </div>

      <h2>Uploaded Images</h2>
      <div
        style={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}>
        {images.map((image, index) => (
          <>
            <div key={index} style={{ margin: "10px" }}>
              <img
                src={image.imgURL} // Replace with the correct field for the image URL
                alt={`Image ${index + 1}`}
                style={{ height: "150px", objectFit: "cover" }}
              />
              <Typography variant="body1">
                Current Cashback Info {image.cashBackInfo}
              </Typography>
            </div>
          </>
        ))}
      </div>
    </div>
  );
};

export default GlobalAsset;
