import React, { useEffect, useState } from "react";
import OffersList from "./OffersList";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { setLoading } from "../../store/actions/actions";
import {
  Box,
  FormControl,
  FormControlLabel,
  Switch,
  Typography,
} from "@mui/material";
import empty from "../../Components/Elements/Images/empty1.gif";

const Offers = () => {
  const [originalData, setOriginalData] = useState([]);
  const [offersData, setOffersData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [sortByPriority, setSortByPriority] = useState(false); // Toggle sorting criterion
  const dispatch = useDispatch();

  const handleLoading = (value) => {
    dispatch(setLoading(value));
    setIsLoading(value);
  };

  const fetchOffers = async () => {
    handleLoading(true);
    try {
      const response = await fetch(
        "https://server.atf-labs.com:3000/all/offers",
        { method: "GET" }
      );

      const data = await response.json();

      if (data.status === true) {
        toast.success(data.message);

        if (data.data && data.data.length > 0) {
          // Ensure all items have required fields
          const sanitizedData = data.data.map((item) => ({
            ...item,
            updatedOn: item.updatedOn || "1970-01-01", // Default if missing
            isPriority: item.isPriority ?? false, // Default to false if missing
          }));
          setOriginalData(sanitizedData);
          applySorting(sanitizedData, sortByPriority);
        } else {
          toast.error("No offers found.");
          setOriginalData([]);
          setOffersData([]);
        }
      } else {
        toast.error(data.message);
      }
    } catch (err) {
      toast.error(`Error fetching offers: ${err.message}`);
    } finally {
      handleLoading(false);
    }
  };

  const applySorting = (data, sortPriority) => {
    const sortedData = [...data];
    if (sortPriority) {
      // Sort by priority (boolean descending: true > false)
      sortedData.sort((a, b) => b.isPriority - a.isPriority);
    } else {
      // Sort by updatedOn (latest dates first)
      sortedData.sort(
        (a, b) =>
          new Date(b.updatedOn).getTime() - new Date(a.updatedOn).getTime()
      );
    }

    setOffersData(sortedData);
  };

  const handleSwitchChange = (event) => {
    const isPrioritySort = event.target.checked;
    setSortByPriority(isPrioritySort);
    applySorting(originalData, isPrioritySort);
  };

  useEffect(() => {
    fetchOffers();
  }, []);

  return (
    <div>
      {/* Sort Switch */}
      <Box display="flex" justifyContent="right" mb={2} alignItems="center">
        <FormControl component="fieldset">
          <FormControlLabel
            control={
              <Switch checked={sortByPriority} onChange={handleSwitchChange} />
            }
            label={sortByPriority ? "Sort by Priority" : "Sort by Updated On"}
            labelPlacement="top"
          />
        </FormControl>
      </Box>

      {/* Offer List */}
      {offersData.length > 0 ? (
        <OffersList offersData={offersData} isAll={true} />
      ) : (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          height="100vh">
          <Box textAlign="center">
            <Typography variant="h6">No offers found</Typography>
            <img src={empty} width="500px" alt="No Products" />
          </Box>
        </Box>
      )}
    </div>
  );
};

export default Offers;
