import React, { useState, useEffect } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Paper,
  Button,
} from "@mui/material";
import { Grid } from "@mui/material";
import { useDispatch } from "react-redux";
import { setLoading } from "../../../store/actions/actions";
import { getShops } from "../../../API/ShopApi";
import ShopItem from "../ListItem/ShopItem";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Sort from "../../Elements/Sort/Sort";
export default function Home() {
  const [verify, setVerify] = useState([]);
  const [filteredVerify, setFilteredVerify] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate(); // For redirection

  const handleLoading = (value) => {
    dispatch(setLoading(value));
  };

  const fetchData = async () => {
    handleLoading(true);
    try {
      const response = await getShops();
      if (response.status === true) {
        // Sorting data by updatedAt (latest first)
        const sortedData = response.data.sort(
          (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
        );

        handleLoading(false);
        setVerify(sortedData);
        setFilteredVerify(sortedData);
        toast.success(response.message);
      }
    } catch (error) {
      handleLoading(false);
      toast.error("Error fetching data: " + error.message);
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleSearch = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchQuery(value);

    if (value.trim() === "") {
      setFilteredVerify(verify);
    } else {
      const filteredData = verify.filter((shop) => {
        const name = shop.name?.toLowerCase() || "";
        const kartaName = shop.kartaName?.toLowerCase() || "";
        const mobile = shop.mobile?.toString() || "";
        return (
          name.includes(value) ||
          kartaName.includes(value) ||
          mobile.includes(value)
        );
      });
      setFilteredVerify(filteredData);
    }
  };

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2} alignItems="flex-end">
          <Grid xs={8} sx={{ direction: "rtl" }}>
            <Typography
              variant="h3"
              fontWeight="600"
              marginTop="40px"
              marginRight="12rem">
              Store List
            </Typography>
          </Grid>
          <Grid xs={4} sx={{ display: "flex", alignContent: "center" }}>
            <TextField
              sx={{ marginLeft: "10rem" }}
              label="Search"
              value={searchQuery}
              onChange={handleSearch}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={() => navigate("createShop")}
              sx={{ marginLeft: "1rem" }}>
              Create Shop
            </Button>
          </Grid>
        </Grid>
      </Box>

      <TableContainer
        component={Paper}
        sx={{
          width: "86%",
          margin: "20px auto",
          marginLeft: "9%",
          boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
          borderRadius: "8px",
        }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <b>Image</b>
              </TableCell>
              <TableCell>
                <b>Shop Name</b>
              </TableCell>
              <TableCell>
                <b>Owner Name</b>
              </TableCell>
              <TableCell>
                <b>Mobile</b>
              </TableCell>
              <TableCell>
                <b>Actions</b>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Sort data={verify} onFilter={setFilteredVerify} />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(filteredVerify.length > 0 ? filteredVerify : verify).map(
              (shop) => (
                <>
                  <ShopItem
                    key={shop._id}
                    id={shop._id}
                    imgUrl={shop.shopImg}
                    isPriority={shop.isPriority}
                    Shop_Name={shop.name}
                    Karta_Name={shop.kartaName}
                    Mobile={shop.mobile}
                  />
                </>
              )
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
