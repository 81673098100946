// reducer.js
import { SET_VALUE } from "../actions/actions";

const initialState = {
  value: "productList", // Default value
};

const valueReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_VALUE:
      return {
        ...state,
        value: action.payload || initialState.value,
      };
    default:
      return state;
  }
};

export default valueReducer;
