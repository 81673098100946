// import React from "react";
// import { useEffect, useState } from "react";
// import Typography from "@mui/material/Typography";
// import {
//   Box,
//   Button,
//   Divider,
//   List,
//   ListItem,
//   ListItemText,
// } from "@mui/material";
// import BlockIcon from "@mui/icons-material/Block";
// import empty from "../Elements/Images/empty1.gif";
// import BlockedShopCard from "./Cards/BlockedShopCards";
// import { backendApiUrl } from "../../constants";

// export default function BlockedShops() {
//   const [verify, setVerify] = useState([]);

//   const fetchData = () => {
//     fetch(`${backendApiUrl}/Shop/GetAllBlockedShops`)
//       .then((response) => response.json())
//       .then((data) => {
//         // Sort data by updatedAt (latest first)
//         const sortedData = data.sort(
//           (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
//         );
//         setVerify(sortedData);
//       })
//       .catch((error) => {
//         console.error("Error fetching data:", error);
//       });
//   };

//   useEffect(() => {
//     fetchData();
//   }, []);

//   return (
//     <Box>
//       {verify.length > 0 ? (
//         <Box display="flex" padding="10vh">
//           {verify.map((verifieduser) => (
//             <List
//               sx={{
//                 width: "100%",
//                 maxWidth: 800,
//                 bgcolor: "background.paper",
//               }}>
//               <ListItem alignItems="flex-start">
//                 <ListItemText
//                   primary={verifieduser?.name}
//                   secondary={
//                     <React.Fragment>
//                       <Typography
//                         sx={{ display: "inline" }}
//                         component="span"
//                         variant="body2"
//                         color="text.primary">
//                         {verifieduser.kartaName}
//                       </Typography>{" "}
//                       <Box></Box>
//                       {verifieduser.mobile}
//                       <Box>
//                         <Button
//                           variant="contained"
//                           color="error"
//                           startIcon={<BlockIcon />}></Button>
//                       </Box>
//                     </React.Fragment>
//                   }
//                 />
//               </ListItem>
//               <Divider variant="inset" component="li" />
//             </List>
//             // <BlockedShopCard
//             //   Shop_Name={verifieduser.name}
//             //   Karta_Name={verifieduser.kartaName}
//             //   Mobile={verifieduser.mobile}
//             //   imgUrl={verifieduser.shopImg}
//             //   id={verifieduser._id}
//             // />
//           ))}
//         </Box>
//       ) : (
//         <Box
//           display="inlineFlex"
//           alignItems="center"
//           height="100vh"
//           justifyContent="center">
//           <Box>
//             <Typography
//               align="center"
//               mb="25vh"
//               variant="h3"
//               style={{ zIndex: "3" }}>
//               Blocked Shops
//             </Typography>
//             <Box display="flex" justifyContent="center">
//               <img src={empty} width="500px"></img>
//             </Box>
//           </Box>
//         </Box>
//       )}
//     </Box>
//   );
// }

import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { setLoading } from "../../store/actions/actions";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import empty from "../../Components/Elements/Images/empty1.gif";
import ShopBannerRow from "./ListItem/ShopBannerRow";
import Sort from "../Elements/Sort/Sort";

const AllBanner = () => {
  const [originalData, setOriginalData] = useState([]);
  const [bannerData, setBannerData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const handleLoading = (value) => {
    dispatch(setLoading(value));
    setIsLoading(value);
  };

  const fetchOffers = async () => {
    handleLoading(true);
    try {
      const response = await fetch(
        "https://server.atf-labs.com:3000/all/shopBanners",
        { method: "GET" }
      );

      const data = await response.json();

      if (data.status === true) {
        toast.success(data.message);
        if (data.data && data.data.length > 0) {
          // Ensure all items have required fields
          setOriginalData(data.data);
        } else {
          toast.error("No banner found.");
          setOriginalData([]);
        }
      } else {
        toast.error(data.message);
      }
    } catch (err) {
      toast.error(`Error fetching banner: ${err.message}`);
    } finally {
      handleLoading(false);
    }
  };

  useEffect(() => {
    fetchOffers();
  }, []);

  return (
    <>
      {/* Offer List */}
      <Box>
        <Typography
          variant="h3"
          align="center"
          fontWeight="600"
          marginTop="5vh">
          Shop Banners
        </Typography>
      </Box>

      {originalData.length > 0 ? (
        <Box>
          <TableContainer
            component={Paper}
            sx={{
              width: "86%",
              margin: "20px auto",
              marginLeft: "9%",
              boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
              borderRadius: "8px",
            }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="left">
                    <b>Banner Image</b>
                  </TableCell>
                  <TableCell align="left">
                    <b>Shop Name</b>
                  </TableCell>
                  <TableCell align="left">
                    <b>Actions</b>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Sort data={originalData} onFilter={setBannerData} />
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(bannerData.length > 0 ? bannerData : originalData).map(
                  (banner) => (
                    <ShopBannerRow
                      key={banner._id}
                      name={banner.shopBanner}
                      shopId={banner.shopId._id}
                      shopName={banner.shopId.name}
                      id={banner._id}
                      isActive={banner.isActive}
                    />
                  )
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      ) : (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          height="100vh">
          <Box textAlign="center">
            <Typography variant="h6">No banner found</Typography>
            <img src={empty} width="500px" alt="No Products" />
          </Box>
        </Box>
      )}
    </>
  );
};

export default AllBanner;
